/* eslint-disable no-nested-ternary */
export default function KRPANO(panoId) {
  function krpanoInstance() {
    return document.getElementById(panoId);
  }

  function init(targetElementId, xmlPath, defaultSettings) {
    let settings = {};
    if (defaultSettings) settings = defaultSettings;
    settings['image.prealign'] = '';
    settings.panoId = panoId;

    try {
      window.embedpano({
        target: targetElementId,
        // swf: '/core/tour.swf',
        html5: 'only+webgl',
        xml: xmlPath,
        bgcolor: '#000000',
        // mobilescale: 1.0,
        // passQueryParameters: true,
        id: panoId,
        vars: settings,
        initvars: defaultSettings,
        webglsettings: { preserveDrawingBuffer: true },
        consolelog: process.env.NODE_ENV === 'development',
      });
      if (window.listKrpanoInstance) {
        const { listKrpanoInstance } = window;
        window.listKrpanoInstance = [...listKrpanoInstance, panoId];
      } else window.listKrpanoInstance = [panoId];
    } catch (e) {
      console.log(e);
    }

    on('onloadcomplete', () => {
      const myCustomEventOnLoadComplete = new Event('onloadcomplete');
      if (krpanoInstance())
        krpanoInstance().dispatchEvent(myCustomEventOnLoadComplete);
    });

    on('onviewchange', () => {
      const myCustomEventOnviewchange = new Event('onviewchange');
      if (krpanoInstance())
        krpanoInstance().dispatchEvent(myCustomEventOnviewchange);
    });
    on('ondoubleclick', () => {
      const myCustomEventOnClick = new Event('ondoubleclick');
      if (krpanoInstance())
        krpanoInstance().dispatchEvent(myCustomEventOnClick);
    });
    on('onmousewheel', () => {
      const myCustomEventOnMouseWheel = new Event('onmousewheel');
      if (krpanoInstance())
        krpanoInstance().dispatchEvent(myCustomEventOnMouseWheel);
    });
    on('onmouseup', () => {
      const myCustomEventOnMouseUp = new Event('onmouseup');
      if (krpanoInstance())
        krpanoInstance().dispatchEvent(myCustomEventOnMouseUp);
    });
    on('onmousedown', () => {
      const myCustomEventOnMouseDown = new Event('onmousedown');
      if (krpanoInstance())
        krpanoInstance().dispatchEvent(myCustomEventOnMouseDown);
    });
    on('onnewpanovr', () => {
      const myCustomEventOnNewPanoVR = new Event('onnewpanovr');
      if (krpanoInstance())
        krpanoInstance().dispatchEvent(myCustomEventOnNewPanoVR);
    });
    window[panoId] = krpanoInstance();
  }

  function removePano() {
    if (window.listKrpanoInstance)
      window.listKrpanoInstance = window.listKrpanoInstance.filter(
        item => item !== panoId,
      );

    window.removepano(panoId);
  }

  function loadScene(
    urlImage,
    vars,
    xml,
    transition = 'NOBLEND',
    view = '<view />',
    mode = '',
    sceneChilds = [],
    config = {},
    fovDefault = 90,
    sceneType,
    dollhouseXml = '',
    isShowDollhouseOnStart = false,
  ) {
    let varsString = '';
    if (!vars) {
      vars = null;
    } else {
      Object.entries(vars).forEach(([key, value]) => {
        varsString += `${key}=${value}&`;
      });
    }

    let krpanoXml = `<krpano syncother="false">`;

    krpanoXml += `
      <action name="startup">
        set(view.oz, 0.0);
        clearinterval(day2dust);
    `;
    if (isShowDollhouseOnStart)
      krpanoXml += `dollhouse_view(true);</action>${dollhouseXml}`;
    else {
      if (mode === 'day2dust' && sceneChilds.length) {
        krpanoXml += `setinterval(day2dust, ${config.durationTime || 3},
              if(sceneActive === "scene_01", loadscene(scene_00, null, MERGE|KEEPMOVE|KEEPVIEW|KEEPHOTSPOTS, BLEND(${
                config.waitTime || 0.5
              }, easeInCubic));, loadscene(scene_01, null, MERGE|KEEPMOVE|KEEPVIEW|KEEPHOTSPOTS, BLEND(${
                config.waitTime || 0.5
              }, easeInCubic));););`;
      }
      krpanoXml += '</action>';

      krpanoXml += '<include url="%$mypath%/core/sync.xml" />';

      if (sceneType === 'flat')
        krpanoXml += '<include url="%$mypath%/core/plugins/flat-scene.xml" />';
      else krpanoXml += `<control mode="follow" />`;
      if (xml) {
        krpanoXml += `${xml}${view}`;
        krpanoXml += `
        <scene name="scene_00" onstart="set(sceneActive, scene_00); set(sceneid, null);" nogrid="true">
          ${xml}${view}
        </scene>
      `;
      } else {
        krpanoXml +=
          sceneType === 'flat'
            ? `<preview url="${urlImage}" /><image hfov="1.00" vfov="1.000000" voffset="0.00"><flat url="${urlImage}"/></image>
      <scene name="scene_00" onstart="set(sceneActive, scene_00); set(sceneid, null);" nogrid="true">
      <preview url="${urlImage}" /><image hfov="1.00" vfov="1.000000" voffset="0.00"><flat url="${urlImage}"/></image>
      </scene>`
            : `<preview url="${urlImage}" />
      <image><sphere url="${urlImage}" /></image>
      ${view}
      <scene name="scene_00" onstart="set(sceneActive, scene_00); set(sceneid, null);" nogrid="true">
      <preview url="${urlImage}" />
      <image><sphere url="${urlImage}" /></image>
      ${view}
      </scene>`;
      }

      if (mode === 'day2dust' && sceneChilds.length) {
        sceneChilds.forEach((sceneChild, index) => {
          krpanoXml += sceneChild.xml
            ? `<scene name="scene_0${
                index + 1
              }" onstart="set(sceneActive, scene_0${
                index + 1
              }); set(sceneid, null);" nogrid="true">
              ${sceneChild.xml}${view}
            </scene>`
            : `<scene name="scene_0${
                index + 1
              }" onstart="set(sceneActive, scene_0${
                index + 1
              }); set(sceneid, null);" nogrid="true">
              <preview url="${sceneChild.urlTour}" />
              <image><sphere url="${sceneChild.urlTour}" /></image>
              ${view}
          </scene>`;
        });
      }

      if (
        mode === 'staging' &&
        sceneChilds.length &&
        config.modeDisplay === ''
      ) {
        sceneChilds.forEach((sceneChild, index) => {
          krpanoXml += sceneChild.xml
            ? `<scene name="scene_0${
                index + 1
              }" nogrid="true" onstart="set(sceneid, null);">
              ${sceneChild.xml}${view}
            </scene>`
            : `<scene name="scene_0${
                index + 1
              }" nogrid="true" onstart="set(sceneid, null);">
              <preview url="${sceneChild.urlTour}" />
              <image><sphere url="${sceneChild.urlTour}" /></image>
              ${view}
          </scene>`;
        });
      }

      krpanoXml += `<hotspot name="hotspot_media_overlay" zorder="1" distorted="true" ath="0" atv="0" enabled="false" type="text" renderer="webgl" bgcolor="0x000000" bgalpha="1.0" width="100%" height="100%" alpha="0.85" scale="1" flying="1" visible="false" />`;
      krpanoXml += dollhouseXml;
    }
    krpanoXml += '</krpano>';

    krpanoInstance().call(
      `
        ${
          transition === 'OPENBLEND(0.5, 0.0, 0.75, 0.05, linear)' &&
          sceneType !== 'flat'
            ? 'wait(1.1)'
            : ''
        };
        loadxml(${krpanoXml}, ${varsString || null}, KEEPPLUGINS,
          ${sceneType === 'flat' ? null : transition}, startup);
        ${
          sceneType !== 'flat' && !isShowDollhouseOnStart
            ? `set("view.fov", ${fovDefault})`
            : ``
        }
        ${
          transition === 'OPENBLEND(0.5, 0.0, 0.75, 0.05, linear)' &&
          sceneType !== 'flat' &&
          !isShowDollhouseOnStart
            ? `wait(0.25);
            trace(view.fov);
            tween(view.fov, ${fovDefault}, 2);`
            : ''
        };
      `,
    );
  }

  function on(eventName, callback, eventId) {
    if (typeof callback === 'function' && krpanoInstance() !== null) {
      if (eventId) {
        try {
          krpanoInstance().set(`events['${eventId}'].${eventName}`, callback);
        } catch (e) {
          console.log(e);
        }
      } else {
        // Register to global event
        try {
          krpanoInstance().set(`events.${eventName}`, callback);
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  return {
    init,
    krpano: krpanoInstance(),
    loadScene,
    removePano,
  };
}

/**
 *
 * LoadingContainer
 *
 */
import { I_Scene } from '@containers/Home/types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import KrpanoBasic from '@components/Krpano/KrpanoBasic';
import { Button, Input, notification } from 'antd';
import { ProtectPasswordWrapper } from './styled';
import { useSelector } from 'react-redux';
import { selectIsMatterportProject } from '@containers/Home/selectors';
import dynamic from 'next/dynamic';

const MatterportContainer = dynamic(
  () => import('@containers/Home/components/MatterportContainer'),
  { ssr: false },
);

const { Password } = Input;

interface Props {
  scene?: I_Scene;
  password: string;
  onSubmit: () => void;
}

export const ProtectPassword = memo(({ scene, password, onSubmit }: Props) => {
  const [pw, setPw] = useState('');

  const isMatterportProject = useSelector(selectIsMatterportProject);

  const urlTour = useMemo(
    () =>
      scene?.media?.src_preview || scene?.media?.src_thumb || scene?.media?.src,
    [scene],
  );

  const onOK = useCallback(() => {
    if (pw !== password) {
      notification.error({
        message: 'Error',
        description: 'Incorrect password.',
        placement: 'topRight',
      });
    } else onSubmit();
  }, [password, pw, onSubmit]);

  return (
    <ProtectPasswordWrapper>
      {isMatterportProject ? (
        <MatterportContainer />
      ) : (
        <KrpanoBasic
          urlTour={urlTour || ''}
          scene={scene || undefined}
          krpanoId="previewViewer"
          divId="previewContainer"
          styleContainer={{
            height: '100%',
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0,
          }}
        />
      )}
      <div className="overlay">
        <div className="form">
          <div className="bg" />
          <div className="icon">
            <i aria-hidden className="fas fa-lock" />
          </div>
          <div className="content">
            <div className="title">This Project is locked by owner!</div>
            <div className="input">
              <Password
                size="large"
                autoFocus
                value={pw}
                onChange={e => setPw(e.target.value)}
                placeholder="Please enter password"
                onPressEnter={onOK}
              />
            </div>
            <div className="action">
              <Button disabled={!pw} type="primary" onClick={onOK}>
                Access
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ProtectPasswordWrapper>
  );
});

export default ProtectPassword;

/**
 *
 * styled LoadingContainer
 *
 */
import styled from 'styled-components';
import { FlexColCenter, FlexRowCenter } from '@styles/global-styles';

export const ProtectPasswordWrapper = styled.div`
  color: #fff;
  width: 100vw;
  height: 100vh;
  .overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${FlexRowCenter};
    position: absolute;
    // backdrop-filter: blur(4px);
    background: rgba(0, 0, 0, 0.25);
    .form {
      width: 360px;
      padding: 48px 24px 24px;
      max-width: 90%;
      position: relative;
      background: rgba(0, 0, 0, 0.2);
      background-image: linear-gradient(
        114deg,
        rgb(255 255 255 / 1%),
        rgb(255 255 255 / 12%) 43%,
        transparent 43.1%,
        transparent
      );
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10px);
      border: 1px solid rgba(255, 255, 255, 0.46);
      .icon {
        position: absolute;
        width: 56px;
        height: 56px;
        top: -28px;
        left: 50%;
        transform: translateX(-50%);
        ${FlexRowCenter};
        color: var(--color-text);
        background: var(--color-primary);
        border-radius: 50%;
        font-size: 1.5rem;
        z-index: 2;
      }
      .content {
        width: 100%;
        z-index: 2;
        ${FlexColCenter};
        .title {
        }
        .input {
          margin: 20px 0;
          width: 100%;
          .ant-input-password {
            background: rgb(0 0 0 / 0.15);
            border: 1px solid rgba(255, 255, 255, 0.5);
            box-sizing: border-box;
            border-radius: 6px;
            width: 100%;
            color: #fff;
            &-icon {
              color: #fff;
              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  color: #fff;
                }
              }
            }
            input {
              background: transparent;
              color: inherit;
            }
            &.ant-input-affix-wrapper-focused {
              border-color: #fff;
              box-shadow: 0 0 0 4px rgb(255 255 255 / 0.25);
            }
          }
        }
        .action {
          .ant-btn {
            background: var(--color-primary);
            color: var(--color-text);
            box-shadow: 0px 4px 12px rgb(0 0 0 / 0.2);
            border-radius: 6px;
            border: none;
            padding: 8px 36px;
            height: auto;
          }
        }
      }
    }
  }
`;

/* eslint-disable global-require */
import { I_NadirConfig, I_Scene } from '@containers/Home/types';
import { isServer } from '@utils/helper';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  CSSProperties,
  memo,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  useZoomKrpano,
  useHLookatKrpano,
  useVLookatKrpano,
} from '@containers/Home/global/TourStats';
import { DEFAULT_CONFIG_SCENE_PLUGIN } from '@containers/Home/constants';
import KRPANO from './KrpanoTemplate';

export type Type_RefFuncKrpano = {
  getContainer: () => HTMLDivElement;
  viewPlanet: () => void;
  viewNormal: () => void;
  viewFullscreen: () => void;
  viewMirrorBall: () => void;
  viewWebvr: () => void;
  toggleGyro: () => void;
  startAutoRotate: ({
    speed,
    wait_time,
  }: {
    speed?: number;
    wait_time?: number;
  }) => void;
  stopAutoRotate: () => void;
  toLeft: () => void;
  toRight: () => void;
  stopToHorizontal: () => void;
  toUp: () => void;
  toDown: () => void;
  stopToVertical: () => void;
};

interface IPropKrpano {
  scene?: I_Scene;
  urlTour?: string;
  divId?: string;
  krpanoId?: string;
  nadirConfig?: I_NadirConfig;
  // ! RENDER UI
  children?: React.ReactNode;
  styleContainer?: CSSProperties;
}

const KrpanoBasic = forwardRef(
  (
    {
      scene,
      urlTour,
      divId = 'container',
      krpanoId = 'krpanoContainer',
      nadirConfig,
      children,
      styleContainer = { height: '100%' },
    }: IPropKrpano,
    ref: React.Ref<Type_RefFuncKrpano>,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null!);

    const [krpanoView, setKrpanoView] = useState<any>();

    const [zoomKrpano] = useZoomKrpano('zoom');
    const [hlookatKrpano] = useHLookatKrpano('hlookat');
    const [vlookatKrpano] = useVLookatKrpano('vlookat');

    const _window = isServer ? undefined : (window as any);

    const onSetConfigPlugin = useCallback(() => {
      Object.entries(DEFAULT_CONFIG_SCENE_PLUGIN).forEach(
        ([keyParent, valueParent]) => {
          Object.entries(valueParent).forEach(([keyChild, valueChild]) => {
            const value =
              scene &&
              scene?.configPlugin &&
              scene?.configPlugin[keyParent] &&
              scene?.configPlugin[keyParent][keyChild]
                ? scene?.configPlugin[keyParent][keyChild]
                : valueChild;
            _window[krpanoId].set(`plugin[${keyParent}].${keyChild}`, value);
          });
        },
      );
    }, [_window, krpanoId, scene]);

    /**
     * ! SET DEFAULT VIEW, LIMIT VIEW, LIMIT ZOOM
     */
    const defaultConfigScene = useCallback(() => {
      const transition =
        scene?.config?.typeTransition === '3d'
          ? 'OPENBLEND(0.5, 0.0, 0.75, 0.05, linear)'
          : scene?.config?.typeTransition;

      let view = '<view ';

      const fovDefault = scene?.config?.default_view?.zoom_lv || 90;

      view += ` hlookat="${hlookatKrpano || 0}"`;
      view += ` vlookat="${vlookatKrpano || 0}"`;
      view += ` fov="${zoomKrpano || 90}"`;

      view += ` fovmin="${
        scene?.config?.zoom_limit?.min !== undefined
          ? scene.config.zoom_limit.min
          : 60.0
      }"`;
      view += ` fovmax="
          ${
            scene?.config?.zoom_limit?.max !== undefined
              ? scene.config.zoom_limit.max
              : 150.0
          }"`;
      view += ' limitview="range"';
      view += ` hlookatmin="${
        scene?.config?.limit_view?.left !== undefined
          ? scene?.config?.limit_view.left
          : -180
      }"`;
      view += ` hlookatmax="${
        scene?.config?.limit_view?.right !== undefined
          ? scene?.config?.limit_view.right
          : 180
      }"`;
      view += ` vlookatmin="${
        scene?.config?.limit_view?.bottom !== undefined
          ? scene?.config?.limit_view.bottom
          : 90
      }"`;
      view += ` vlookatmax="${
        scene?.config?.limit_view?.top !== undefined
          ? scene?.config?.limit_view.top
          : -90
      }"`;
      view += ' />';
      return { view, transition, fovDefault };
    }, [scene, hlookatKrpano, vlookatKrpano, zoomKrpano]);

    /**
     * @param {scene}: current scene selected
     * @param {sceneXml}: default by null, use krpano_xml on media if exist.
     */
    const loadSceneToView = useCallback(
      (myscene: I_Scene) => {
        if (myscene && myscene.media && krpanoView) {
          const { transition, view } = defaultConfigScene();
          krpanoView.loadScene(
            urlTour,
            null,
            myscene.media.krpano_xml || null,
            transition,
            `${view}
              ${
                scene?.configPlugin?.lensflare?.enabled &&
                scene?.configPlugin?.lensflare?.ath &&
                scene?.configPlugin?.lensflare?.atv
                  ? `<include url="%$mypath%/core/skin/lensflare.xml" />
                <lensflare_settings editor="false" ath="${
                  scene?.configPlugin?.lensflare?.ath !== undefined
                    ? scene?.configPlugin?.lensflare?.ath
                    : DEFAULT_CONFIG_SCENE_PLUGIN.lensflare?.ath
                }" atv="${
                  scene?.configPlugin?.lensflare?.atv !== undefined
                    ? scene?.configPlugin?.lensflare?.atv
                    : DEFAULT_CONFIG_SCENE_PLUGIN.lensflare?.atv
                }" sun="${
                  scene?.configPlugin?.lensflare?.sun !== undefined
                    ? scene?.configPlugin?.lensflare?.sun
                    : DEFAULT_CONFIG_SCENE_PLUGIN.lensflare?.sun
                }" glare="${
                  scene?.configPlugin?.lensflare?.glare !== undefined
                    ? scene?.configPlugin?.lensflare?.glare
                    : DEFAULT_CONFIG_SCENE_PLUGIN.lensflare?.glare
                }" r_ring="${
                  scene?.configPlugin?.lensflare?.r_ring !== undefined
                    ? scene?.configPlugin?.lensflare?.r_ring
                    : DEFAULT_CONFIG_SCENE_PLUGIN.lensflare?.r_ring
                }" exposure="true" exposure_bias="${
                  scene?.configPlugin?.lensflare?.exposure_bias !== undefined
                    ? scene?.configPlugin?.lensflare?.exposure_bias
                    : DEFAULT_CONFIG_SCENE_PLUGIN.lensflare?.exposure_bias
                }"/>
                <plugin name="pp_light" devices="html5" keep="false"
                  url="%$mypath%/core/plugins/pp_light.js"
                  enabled="true"
                  exposure="0.0"
                  lights="0.0"
                  shadows="0.0"
                  filterrange="60.0"
                  masking="1.0"
                  quality="7"
                  order=""
                  phase="2"
                />`
                  : ''
              }
          `,
            zoomKrpano || 90,
          );
        }
      },
      [defaultConfigScene, krpanoView, scene, urlTour, zoomKrpano],
    );

    const addHotspotNadirKrpano = useCallback(() => {
      const krpano = _window[krpanoId];

      const name = 'nadirlogo';
      krpano.set(`hotspot['${name}'].ath`, +0);
      krpano.set(`hotspot['${name}'].atv`, +90);
      krpano.set(`hotspot['${name}'].zoom`, false);
      krpano.set(`hotspot['${name}'].scale`, nadirConfig?.scale || 1);
      krpano.set(`hotspot['${name}'].rotatewithview`, 'true');
      krpano.set(`hotspot['${name}'].distorted`, true);
      krpano.set(`hotspot['${name}'].url`, nadirConfig?.src || '');
      krpano.set(
        `hotspot['${name}'].visible`,
        !!(nadirConfig?.isEnabled && nadirConfig?.src),
      );
      if (nadirConfig?.link)
        krpano.set(`hotspot['${name}'].onclick`, () => {
          window.open(nadirConfig?.link, '_blank');
        });

      if (nadirConfig?.isEnabled && nadirConfig?.src)
        krpano.call(`addhotspot('${name}')`);
    }, [_window, krpanoId, nadirConfig]);

    /**
     * @event {load complete}
     * @event {trigger sound}
     */

    const onLoadComplete = useCallback(() => {
      if (scene) {
        onSetConfigPlugin();

        _window[krpanoId].set('view.limitview', 'range');

        _window[krpanoId].set('showerrors', false);

        _window[krpanoId].call(`followmouse_disable()`);

        addHotspotNadirKrpano();
      }
    }, [scene, _window, krpanoId, onSetConfigPlugin, addHotspotNadirKrpano]);

    /**
     * !CROSS function
     */
    useImperativeHandle(ref, () => ({
      getContainer() {
        return containerRef.current;
      },
      // ! VIEW KRPANO
      viewPlanet() {
        _window[krpanoId].call('cm_littleplanet_view');
      },
      viewNormal() {
        _window[krpanoId].call('cm_normal_view');
      },
      viewMirrorBall() {
        _window[krpanoId].call('mirror_ball_view');
      },
      viewFullscreen() {
        _window[krpanoId].call('switch(fullscreen)');
      },
      viewWebvr() {
        _window[krpanoId].call('webvr.enterVR()');
      },
      toggleGyro() {
        _window[krpanoId].call('toggleGyroscope()');
      },
      startAutoRotate({ speed = 10, wait_time = 20 }) {
        _window[krpanoId].call(`startrotation(${speed}, ${wait_time})`);
      },
      stopAutoRotate() {
        _window[krpanoId].call('stoprotation');
      },
      toLeft() {
        _window[krpanoId].call('set(hlookat_moveforce,-0.75)');
      },
      toRight() {
        _window[krpanoId].call('set(hlookat_moveforce,+0.75)');
      },
      stopToHorizontal() {
        _window[krpanoId].call('set(hlookat_moveforce,0)');
      },
      toUp() {
        _window[krpanoId].call('set(vlookat_moveforce,-0.75)');
      },
      toDown() {
        _window[krpanoId].call('set(vlookat_moveforce,+0.75)');
      },
      stopToVertical() {
        _window[krpanoId].call('set(vlookat_moveforce,0)');
      },
    }));

    /**
     * @purpose init krpano && asign event to.
     */
    useEffect(() => {
      if (!krpanoView && krpanoId) setKrpanoView(KRPANO(krpanoId));
    }, [krpanoView, krpanoId]);

    useEffect(() => {
      if (krpanoView)
        krpanoView.init(
          divId,
          `${process.env.NEXT_PUBLIC_ASSETS_URL}/core/tour.xml`,
          {
            mypath: process.env.NEXT_PUBLIC_ASSETS_URL,
          },
        );
    }, [krpanoView, divId]);

    useEffect(() => {
      if (krpanoView && urlTour) {
        if (scene) loadSceneToView(scene);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlTour, krpanoView]);

    useEffect(() => {
      if (_window[krpanoId] && krpanoView) {
        _window[krpanoId].addEventListener('onloadcomplete', onLoadComplete);
        // _window[krpanoId].addEventListener('onviewchange', onChangeView);
        // _window[krpanoId].addEventListener('onmousewheel', onChangeZoom);
        // _window[krpanoId].addEventListener('onmousedown', onTriggerMouseDown);
      }
      return () => {
        if (_window[krpanoId]) {
          _window[krpanoId].removeEventListener(
            'onloadcomplete',
            onLoadComplete,
          );
          // _window[krpanoId].removeEventListener('onviewchange', onChangeView);
          // _window[krpanoId].removeEventListener('onmousewheel', onChangeZoom);
          // _window[krpanoId].removeEventListener(
          //   'onmousedown',
          //   onTriggerMouseDown,
          // );
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [krpanoView, scene]);

    useEffect(
      () => () => {
        if (krpanoView) krpanoView.removePano();
      },
      [krpanoView],
    );

    return (
      <div
        id={divId}
        ref={containerRef}
        style={{
          ...{
            width: '100%',
            height: '250px',
          },
          ...styleContainer,
        }}
      >
        {children}
      </div>
    );
  },
);

export default memo(KrpanoBasic);
